import type { FC } from 'react';

import { Box } from '@chakra-ui/react';

import Image from '~/components/common/Image';

export const Hero: FC = () => {
  return (
    <Box
      id="hero"
      width="100%"
      height={['300px', null, '400px']}
      position="relative"
    >
      <Image
        src="/assets/hero.png"
        width={1125}
        height={900}
        alt="hero image"
        layout="fill"
      />
      <Box
        position="absolute"
        left="50%"
        top="50%"
        width={['240px', null, '430px']}
        height={['104px', null, '186px']}
        transform="translate(-50%, -50%)"
      >
        <Image
          src="/assets/logo_with_tagline.png"
          width={1125}
          height={900}
          alt="logo with tagline"
          layout="fill"
          objectFit="cover"
        />
      </Box>
    </Box>
  );
};
