import type { FC } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { Box, Flex, Spinner, Text } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import { rankingTags } from '~/constants/ranking';
import { clientNames } from '~/graphql/apollo';
import type { Product, ProductsByTagQuery } from '~/graphql/shopify/generated';
import { useProductsByTagLazyQuery } from '~/graphql/shopify/generated';
import { fetchTagProductsDefaultValues } from '~/lib/shopify';

import { RankingCarousel } from './RankingCarousel/index';

type Props = {
  products: ProductsByTagQuery['products'];
};

export const Ranking: FC<Props> = ({ products: defaultValues }) => {
  const [selected, setSelected] = useState<string>('Tops');
  const [products, setProducts] =
    useState<ProductsByTagQuery['products']>(defaultValues);

  const [fetch, { data, loading }] = useProductsByTagLazyQuery({
    variables: {
      ...fetchTagProductsDefaultValues,
      query: `tag:${selected.toLowerCase().split(' ').join('_')}`,
    },
    context: {
      clientName: clientNames.shopify,
    },
  });

  const handleChangeSelected = (next: string) => setSelected(next);

  useEffect(() => {
    if (!data) {
      return;
    }
    setProducts(data?.products);
  }, [data]);

  useEffect(() => {
    if (selected === 'Tops') {
      return;
    }
    fetch();
  }, [selected, fetch]);

  if (!products) {
    return null;
  }
  return (
    <Box bg="#f6f6f6">
      <Box>
        <Header>RANKING</Header>
        <Flex
          pb={[4, null, 10]}
          maxW={['100%', null, 1080]}
          mx="auto"
          overflowX="scroll"
          gap={[2, null, 4]}
          justify={['flex-start', null, 'center']}
          alignItems="center"
          css={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
          }}
        >
          {rankingTags.map((tag) => (
            <Text
              px={6}
              py={2}
              key={tag}
              fontWeight="bold"
              fontSize={[14, null, 15]}
              bg={selected === tag ? 'black' : 'inherit'}
              color={selected === tag ? 'white' : 'inherit'}
              cursor="pointer"
              onClick={() => handleChangeSelected(tag)}
            >
              {tag}
            </Text>
          ))}
        </Flex>
      </Box>

      {loading ? (
        <Flex height={200} justify="center" alignItems="center">
          <Spinner color="black" />
        </Flex>
      ) : (
        <RankingCarousel products={products.nodes as Product[]} />
      )}
    </Box>
  );
};
