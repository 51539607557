import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { Box, useBreakpointValue, Flex } from '@chakra-ui/react';
import { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';

import type { Product } from '~/graphql/shopify/generated';

import { ArrowLeft } from './IconList/ArrowLeft';
import { ArrowRight } from './IconList/ArrowRight';
import { RankingCarouselItem } from './RankingCarouselItem';

type Props = {
  products: Product[];
};

export const RankingCarousel: FC<Props> = ({ products }) => {
  const isClient = typeof window !== 'undefined';
  const [visible, setVisible] = useState<boolean>(false);
  useEffect(() => {
    if (isClient) {
      setVisible(true);
    }
  }, [isClient]);
  const isMobile = useBreakpointValue({ base: true, md: false });
  if (!visible) {
    return null;
  }
  const swiperConfig = isMobile
    ? {
        slidesPerView: 2.5,
        spaceBetween: 8,
      }
    : {
        slidesPerView: 5,
        initialSlide: 1,
        spaceBetween: 40,
        loop: true,
        centeredSlides: true,
        navigation: {
          prevEl: '.prevEl',
          nextEl: '.nextEl',
        },
        modules: [Navigation],
      };
  return (
    <Box pb={[20, null, 24]} ml={isMobile ? 2 : 0}>
      <Swiper {...swiperConfig}>
        {products.map((p, idx) => (
          <SwiperSlide key={p.id}>
            {({ isActive, isPrev, isNext }) => (
              <RankingCarouselItem
                idx={idx}
                product={p}
                rank={idx + 1}
                isFront={isActive || isPrev || isNext}
                isMobile={isMobile}
              />
            )}
          </SwiperSlide>
        ))}
        {!isMobile && (
          <>
            <Flex
              className="prevEl"
              background="black"
              position="absolute"
              top="calc((100% - 86px) / 2)"
              transform="translateY(-50%)"
              left="18%"
              zIndex={3}
              rounded="full"
              color="white"
              justifyContent="center"
              alignItems="center"
              w={8}
              h={8}
              cursor="pointer"
            >
              <ArrowLeft />
            </Flex>
            <Flex
              className="nextEl"
              background="black"
              position="absolute"
              top="calc((100% - 86px) / 2)"
              transform="translateY(-50%)"
              right="18%"
              zIndex={3}
              rounded="full"
              color="white"
              justifyContent="center"
              alignItems="center"
              w={8}
              h={8}
              cursor="pointer"
            >
              <ArrowRight />
            </Flex>
          </>
        )}
      </Swiper>
    </Box>
  );
};
