import type { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { ProductList } from '~/components/common/ProductList';
import type { Product } from '~/graphql/shopify/generated';

import type { TopPageProps } from '..';

type Props = {
  products: TopPageProps['bestSellerProducts'];
};

export const BestSeller: FC<Props> = ({ products }) => {
  if (!products) {
    return null;
  }
  return (
    <Box>
      <ProductList
        header="BEST SELLING"
        products={products.nodes as Product[]}
        moreHref="/best-selling"
        columns={3}
      />
    </Box>
  );
};
