import type { FC } from 'react';

import { Box, useBreakpointValue, chakra } from '@chakra-ui/react';
import dayjs from 'dayjs';
import 'dayjs/locale/ja';

dayjs.locale('ja');
const nextBusinessDay =
  dayjs().day() === 0 ? dayjs().add(2, 'day') : dayjs().add(1, 'day');

export const Announcement: FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box
      textAlign="center"
      p={[2, null, 4]}
      border="1px solid black"
      borderRadius={4}
      mx={[4, null, 'auto']}
      mt={{ base: 2, md: 0 }}
      mb={[2, null, 4]}
      maxWidth={920}
      fontSize={[10, null, 12]}
    >
      本日のご注文は、
      <chakra.span fontWeight={700}>
        {nextBusinessDay.format('M月D日(ddd)')}の発送予定です。
      </chakra.span>{' '}
      {isMobile && <br />}
      （ご注文商品の在庫状況によっては前後する可能性があります。）
    </Box>
  );
};
