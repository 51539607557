import type { GetServerSideProps, NextPageWithLayout } from 'next';

import type { BasePageProps } from '~/components/common/Layout';
import { Layout } from '~/components/common/Layout';
import type { TopPageProps } from '~/components/top';
import { TopPage } from '~/components/top';
import {
  fetchBestSellerProducts,
  fetchNewProducts,
  fetchRufflogProducts,
  fetchShopData,
  fetchTagProducts,
} from '~/lib/shopify';
import { fetchBlogArticles } from '~/lib/shopify/blog';
import { fetchBrands } from '~/lib/shopify/brands';
import { fetchCollaborations } from '~/lib/shopify/collaboration';
import { getTranslations } from '~/utils/translation';

type Props = BasePageProps<TopPageProps>;

export const getServerSideProps: GetServerSideProps<Props> = async ({
  locale,
  res,
}) => {
  res.setHeader(
    'Cache-Control',
    'public, s-maxage=10, stale-while-revalidate=59',
  );
  const [
    layoutProps,
    { products: newProducts },
    { products: bestSellerProducts },
    { collection: rufflogCollection },
    { products: rankingProducts },
    { blog },
    { collections: brands },
    { collections: collaborations },
  ] = await Promise.all([
    fetchShopData(),
    fetchNewProducts(locale),
    fetchBestSellerProducts(locale),
    fetchRufflogProducts(locale),
    fetchTagProducts(locale),
    fetchBlogArticles(),
    fetchBrands(),
    fetchCollaborations(),
  ]);
  return {
    props: {
      ...(await getTranslations(locale)),
      layoutProps,
      newProducts,
      bestSellerProducts,
      rufflogCollection,
      rankingProducts,
      blog,
      brands,
      collaborations,
    },
  };
};

const Top: NextPageWithLayout<Props> = (props) => <TopPage {...props} />;

Top.getLayout = (page) => <Layout pageProps={page.props}>{page}</Layout>;

export default Top;
