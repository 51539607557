import type { FC } from 'react';

import type {
  BlogQuery,
  BrandsQuery,
  CollaborationsQuery,
  CollectionProductsQuery,
  ProductsByTagQuery,
  ProductsQuery,
} from '~/graphql/shopify/generated';

import { Announcement } from '../common/Layout/Announcement';
import { BannerTop } from './BannerTop';
import { BestSeller } from './BestSeller';
import { Brands } from './Brands';
import { Category } from './Category';
import { Collaborated } from './Collaborated';
import { Hero } from './Hero';
import { categoriesMock } from './mock';
import { NewArrival } from './NewArrival';
import { News } from './News';
import { Ranking } from './Ranking';
import { RufflogOriginal } from './RufflogOriginal';

export type TopPageProps = {
  newProducts: ProductsQuery['products'];
  bestSellerProducts: ProductsQuery['products'];
  rufflogCollection: CollectionProductsQuery['collection'];
  rankingProducts: ProductsByTagQuery['products'];
  blog: BlogQuery['blog'];
  brands: BrandsQuery['collections'];
  collaborations: CollaborationsQuery['collections'];
};

export const TopPage: FC<TopPageProps> = ({
  newProducts,
  bestSellerProducts,
  rufflogCollection,
  rankingProducts,
  blog,
  brands,
  collaborations,
}) => (
  <>
    <Announcement />
    <Hero />
    <BannerTop />
    <Category categories={categoriesMock} />
    <NewArrival products={newProducts} />
    <BestSeller products={bestSellerProducts} />
    <RufflogOriginal collection={rufflogCollection} />
    <Collaborated collections={collaborations} />
    <Ranking products={rankingProducts} />
    <Brands collection={brands} />
    <News blog={blog} />
  </>
);
