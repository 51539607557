import NextLink from 'next/link';
import type { FC } from 'react';
import { useMemo } from 'react';

import { Box, Flex, Link, SimpleGrid } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import Image from '~/components/common/Image';

import type { TopPageProps } from '..';

type Props = {
  collection: TopPageProps['brands'];
};

export const Brands: FC<Props> = ({ collection }) => {
  const brands = useMemo(() => {
    const rufflog = collection.nodes.find((b) => b.title === 'RUFFLOG');
    const filtered = collection.nodes.filter(
      (b) => !['VIEW ALL', 'RUFFLOG', 'sale'].includes(b.title),
    );
    if (rufflog) {
      filtered.unshift(rufflog);
    }
    return filtered;
  }, [collection]);
  if (!brands) {
    return null;
  }
  return (
    <Box>
      <Box px={['16px', null, 20]} maxW={1080} mx="auto">
        <Header>BRANDS</Header>
        <SimpleGrid columns={4} spacing={[2, null, 10]}>
          {brands
            .filter((p) => p.image)
            .map((p) => (
              <NextLink
                key={p.id}
                href={`/collections/${p.handle}`}
                passHref
                prefetch={false}
              >
                <Link
                  pos="relative"
                  border="1px solid black"
                  background="white"
                  _hover={{ opacity: 0.7 }}
                >
                  <Image
                    src={p.image?.url}
                    width={200}
                    height={120}
                    alt={p.image?.altText || ''}
                    layout="responsive"
                    objectFit="contain"
                  />
                </Link>
              </NextLink>
            ))}
        </SimpleGrid>
        <Flex justify="center" mt={14} pb={[20, null, 24]}>
          <NextLink href="/collections/our-products" passHref>
            <Link
              px={12}
              py={3}
              bg="black"
              color="#fff"
              fontWeight={700}
              fontSize={18}
              textAlign="center"
              width={['100%', null, '180px']}
            >
              VIEW ALL
            </Link>
          </NextLink>
        </Flex>
      </Box>
    </Box>
  );
};
