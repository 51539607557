import type { FC } from 'react';

import { Box } from '@chakra-ui/react';

import { ProductList } from '~/components/common/ProductList';
import type { Product } from '~/graphql/shopify/generated';

import type { TopPageProps } from '..';

type Props = {
  collection: TopPageProps['rufflogCollection'];
};

export const RufflogOriginal: FC<Props> = ({ collection }) => {
  if (!collection) {
    return null;
  }
  return (
    <Box
      id="rufflog"
      bg=" linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85)), url(/assets/a.png)rgba(255, 255, 255, 0.85)"
    >
      <ProductList
        header="RUFFLOG ORIGINAL"
        products={collection.products.nodes as Product[]}
        moreHref="/collections/rufflog-1"
      />
    </Box>
  );
};
