import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Link, SimpleGrid, Text } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import Image from '~/components/common/Image';

import type { TopPageProps } from '..';

type Props = {
  collections: TopPageProps['collaborations'];
};

export const Collaborated: FC<Props> = ({ collections }) => {
  if (!collections) {
    return null;
  }
  return (
    <Box>
      <Box
        id="collaborations"
        px={['16px', null, 20]}
        pb={6}
        maxW={1080}
        mx="auto"
      >
        <Header>COLLABORATIONS</Header>
        <SimpleGrid columns={[1, null, 2]} spacing={[0, 10]}>
          {collections.edges.map((edge) => (
            <NextLink
              key={edge.node.id}
              href={`/collections/${edge.node.handle}`}
              passHref
            >
              <Link pos="relative" _hover={{ opacity: 0.7 }}>
                <Image
                  src={edge.node.image?.url}
                  width={440}
                  height={280}
                  alt={edge.node.image?.altText || ''}
                  layout="responsive"
                  objectFit="cover"
                  objectPosition="bottom"
                />
                <Text
                  pt={4}
                  pb={6}
                  textAlign="center"
                  fontSize={17}
                  fontWeight="700"
                >
                  {edge.node.title}
                </Text>
              </Link>
            </NextLink>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
