import type { FC } from 'react';

export const ArrowRight: FC = () => (
  <svg
    stroke="currentColor"
    fill="none"
    strokeWidth="2"
    viewBox="0 0 24 24"
    strokeLinecap="round"
    strokeLinejoin="round"
    focusable="false"
    height="100%"
    width="100%"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polyline points="10 16 14 12 10 8"></polyline>
  </svg>
);
