import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Flex, HStack, Link, Spacer, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Image from '~/components/common/Image';
import type { Product } from '~/graphql/shopify/generated';
import { useFormatPriceRange } from '~/utils/price';
import { useStockStatus } from '~/utils/stock';

type Props = {
  idx: number;
  product: Product;
  rank: number;
  isFront: boolean;
  isMobile?: boolean;
};

const priorityIndex = 10;

export const RankingCarouselItem: FC<Props> = ({
  product,
  rank,
  idx,
  isFront,
  isMobile,
}) => {
  const { t } = useTranslation();
  const price = useFormatPriceRange(product.priceRange);
  const stockStatus = useStockStatus(product.totalInventory || 0);
  const getRankColor = (rank: number) => {
    switch (rank) {
      case 1:
        return '#BBB52C';
      case 2:
        return '#7C879D';
      case 3:
        return '#C04E4E';
      default:
        return 'black';
    }
  };
  return (
    <Box pos="relative" opacity={isFront || isMobile ? 1 : 0.3}>
      <NextLink href={`/products/${product.handle}`} prefetch={false} passHref>
        <Link>
          <Box
            pos="relative"
            width={['auto', null, 264]}
            height={['auto', null, 320]}
          >
            {product.featuredImage && (
              <Image
                priority={idx <= priorityIndex}
                src={product.featuredImage?.url || ''}
                width={264}
                height={320}
                alt={product.featuredImage?.altText || ''}
                layout="responsive"
                objectFit="contain"
              />
            )}
            {stockStatus === 'SoldOut' && (
              <Flex
                pos="absolute"
                top={0}
                left={0}
                w="100%"
                h="100%"
                bg="blackAlpha.300"
                opacity={0.7}
                justify="center"
                alignItems="center"
                _hover={{ opacity: 0.9 }}
                _active={{ opacity: 0.9 }}
              >
                <Text
                  color="#fff"
                  fontSize={[12, null, 16]}
                  fontWeight={700}
                  textAlign="center"
                >
                  {t('soldOut')}
                </Text>
              </Flex>
            )}
            <Text
              pos="absolute"
              top={0}
              left={0}
              px={2}
              color="white"
              fontSize={13}
              fontWeight={700}
              bg={getRankColor(rank)}
              zIndex={2}
            >
              {rank}
            </Text>
          </Box>
          <Text mt={4} fontSize={12} fontWeight="bold" pb={2} lineHeight="120%">
            {product.title}
          </Text>
          <HStack>
            <Text fontSize={12} fontWeight="bold">
              {price}
            </Text>
            <Spacer />
            {stockStatus === 'IsLow' && (
              <Text
                px={[1, null, 2]}
                py={1}
                borderColor="blackAlpha.700"
                color="blackAlpha.700"
                backgroundColor="white"
                borderWidth={1}
                fontSize={10}
                fontWeight={700}
              >
                {t('stockIsLow')}
              </Text>
            )}
            {stockStatus === 'SoldOut' && (
              <Text
                px={[1, null, 2]}
                py={1}
                borderColor="black"
                fontSize={10}
                fontWeight={700}
              >
                {t('soldOut')}
              </Text>
            )}
          </HStack>
        </Link>
      </NextLink>
    </Box>
  );
};
