import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, SimpleGrid, Link, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';

import { Header } from '~/components/common/Header';
import Image from '~/components/common/Image';
import { encodeGid } from '~/utils/gid';

import type { TopPageProps } from '..';

type Props = {
  blog: TopPageProps['blog'];
};

export const News: FC<Props> = ({ blog }) => {
  const articles = blog?.articles.nodes;

  return articles ? (
    <Box bg="#f6f6f6" pb={10}>
      <Box px={['16px', null, 20]} mb={20} maxW={1080} mx="auto">
        <Header>NEWS</Header>
        <SimpleGrid columns={[2, null, 3]} spacing={10}>
          {articles.map((a) => (
            <NextLink key={a.id} href={`/news/${encodeGid(a.id)}`} passHref>
              <Link>
                {a.image?.url ? (
                  <Image
                    src={a.image?.url}
                    width={1}
                    height={1}
                    layout="responsive"
                    alt={a.title}
                  />
                ) : (
                  <Box border="1px solid black">
                    <Image
                      src="/assets/news/default.png"
                      width={278}
                      height={200}
                      layout="responsive"
                      alt={a.title}
                    />
                  </Box>
                )}
                <Text fontSize={[10, null, 12]} pt="14px" pb="8px">
                  {dayjs(a.publishedAt).format('YYYY.MM.DD')}
                </Text>
                <Text fontSize={[12, null, 16]} fontWeight={700}>
                  {a.title}
                </Text>
              </Link>
            </NextLink>
          ))}
        </SimpleGrid>
      </Box>
      )
    </Box>
  ) : (
    <></>
  );
};
