export const categoriesMock = [
  {
    id: 'tops',
    handle: 'mock',
    title: 'Tops',
    to: '/collections/all/Tops',
    image: {
      url: '/assets/category/tops.jpg',
      width: 1242,
      height: 2019,
      alt: 'Tops',
    },
  },
  {
    id: 'Pants',
    handle: 'mock',
    title: 'Pants',
    to: '/collections/all/Pants',
    image: {
      url: '/assets/category/pants.jpg',
      width: 1242,
      height: 2019,
      alt: 'Pants',
    },
  },
  {
    id: 'Hats&Caps',
    handle: 'mock',
    title: 'Hats&Caps',
    to: '/collections/all/Hats&Caps',
    image: {
      url: '/assets/category/hats_and_caps.jpg',
      width: 1242,
      height: 2019,
      alt: 'Hats&Caps',
    },
  },
  {
    id: 'outers',
    handle: 'mock',
    title: 'Outers',
    to: '/collections/all/Outers',
    image: {
      url: '/assets/category/outers.webp',
      width: 1242,
      height: 2019,
      alt: 'outers',
    },
  },
  {
    id: 'gloves',
    handle: 'mock',
    title: 'Gloves',
    to: '/collections/all/Gloves',
    image: {
      url: '/assets/category/gloves.webp',
      width: 1242,
      height: 2019,
      alt: 'Gloves',
    },
  },
  {
    id: 'Bags&Caddie Bags',
    handle: 'mock',
    title: 'Bags&Caddie Bags',
    to: '/collections/all/Bags&Caddie_Bags',
    image: {
      url: '/assets/category/bags.jpg',
      width: 1242,
      height: 2019,
      alt: 'Bags&Caddie Bags',
    },
  },
  {
    id: 'accessories',
    handle: 'mock',
    title: 'Accessories',
    to: '/collections/all/Accessories',
    image: {
      url: '/assets/category/accessories.jpg',
      width: 1242,
      height: 2019,
      alt: 'accessories',
    },
  },
];
