import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Flex, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import Image from '~/components/common/Image';
import { ProductList } from '~/components/common/ProductList';
import type { Product } from '~/graphql/shopify/generated';

import type { TopPageProps } from '..';

type Props = {
  products: TopPageProps['newProducts'];
};

export const NewArrival: FC<Props> = ({ products }) => {
  const { t } = useTranslation('common');
  if (!products) {
    return null;
  }
  return (
    <Box id="new-arrival" bg="#f6f6f6">
      <ProductList
        header={t('newArrival')}
        products={products.nodes as Product[]}
        moreHref="/new-arrival"
      />
      <Flex px={['16px', null, 20]} justifyContent="center" pb={[10, null, 20]}>
        <NextLink href="https://www.instagram.com/rufflog/" passHref>
          <Link w={['100%', null, 600]} target="_blank">
            <Image
              src="/assets/banner/instagram-banner.png"
              width={343}
              height={90}
              layout="responsive"
              alt="instagram banner"
            />
          </Link>
        </NextLink>
      </Flex>
    </Box>
  );
};
