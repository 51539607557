import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Link, SimpleGrid, Text } from '@chakra-ui/react';

import { Header } from '~/components/common/Header';
import Image from '~/components/common/Image';

type Props = {
  categories: Array<{
    id: string;
    handle: string;
    title: string;
    to: string;
    image: {
      url: string;
      width: number;
      height: number;
      alt: string;
    };
  }>;
};

export const Category: FC<Props> = ({ categories }) => {
  if (!categories) {
    return null;
  }
  return (
    <Box>
      <Box id="category" px={4} pb={10} maxW={1080} mx="auto">
        <Header>CATEGORY</Header>
        <SimpleGrid columns={[4, null, 7]} mb={0}>
          {categories.map((c) => (
            <NextLink key={c.id} href={c.to} passHref>
              <Link
                pos="relative"
                _hover={{ opacity: 0.7 }}
                justifySelf="center"
              >
                <Box
                  w={['72px', null, 100]}
                  h={['72px', null, 100]}
                  p={[1, null, 2]}
                  bg="white"
                  borderRadius="50%"
                  mx={[2, null, 4]}
                  mt={4}
                  border="1px solid"
                  borderColor="blackAlpha.200"
                >
                  <Image
                    src={c.image?.url}
                    width={1}
                    height={1}
                    alt={c.image?.alt || ''}
                    layout="responsive"
                    objectFit="contain"
                    objectPosition="bottom"
                    style={{ borderRadius: '50%' }}
                  />
                </Box>
                <Text pt={2} textAlign="center" fontSize={[10, null, 14]}>
                  {c.title}
                </Text>
              </Link>
            </NextLink>
          ))}
        </SimpleGrid>
      </Box>
    </Box>
  );
};
