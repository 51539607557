import NextLink from 'next/link';
import type { FC } from 'react';

import { Box, Link, useBreakpointValue } from '@chakra-ui/react';

import Image from '~/components/common/Image';

export const BannerTop: FC = () => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  return (
    <Box bg="#f6f6f6" px={[4, null, 40]} py={[4, null, 10]}>
      <NextLink
        href="/articles/Z2lkOi8vc2hvcGlmeS9BcnRpY2xlLzU4Nzc5Nzk1NDc4MQ=="
        passHref
      >
        <Link>
          {isMobile ? (
            <Image
              src="/assets/banner/cool_breath-SP.png"
              alt="banner image"
              layout="responsive"
              width={686}
              height={180}
              objectFit="contain"
            />
          ) : (
            <Image
              src="/assets/banner/cool_breath-PC.png"
              alt="banner image"
              layout="responsive"
              width={1840}
              height={240}
              objectFit="contain"
            />
          )}
        </Link>
      </NextLink>
    </Box>
  );
};
